@import 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,600&display=swap';

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  color: #4d4d4d;
  box-sizing: border-box;
}

.updatedLegend {
  display: inline-flex;
  padding: 10px 0;
  font-size: 10px;
}

.updatedLegend:before,
.prodName.updated:before {
  content: '* ';
  color: blue;
}

.Mui-checked,
.MuiCheckbox-indeterminate {
  color: #ffa300 !important;
}

.Mui-focused {
  color: inherit !important;
}

.Mui-focused.fieldset {
  border-color: red !important;
}

.Stock .MuiTable-root {
  border-top: 1px solid lightgray;
}

.stepPrimary.Next:after {
  content: '';
  display: inline-block;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACxSURBVHgB7dOxDQIhFAbgx+kl17qBJWEKSwpC3MA4giM4gSN4I2igP1awgy1IaAkROmPkgGu9vyL54QvJA4D/DqV0zxg7tpzpcsUwDIAQunHOT1CZTa7QWltCyDOEMGKMrTHmBUuxJeAs1goWsRYQQUPidA9xKJNzbqeUst99VwulpxKhe1yef0HVN0tQ3/dTXF6FEGNuXxGrhYpYCzSLtUIp21yRvpP3/iKlfMCaz7wBq6tfwe1fMaEAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
  height: 16px !important;
  width: 16px !important;
}

.stepPrimary.submit {
  margin-top: 16px;
}

.linkAct {
  text-decoration: underline;
  color: #333;
}

.linkAct:hover {
  cursor: pointer;
}

.productUpdateContainer .quantityContainer span.emphasize {
  background-color: #ffd996 !important;
}

.stepPrimary.floatRight {
  float: right;
}

.msg {
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  display: inline-block;
  width: 100%;
}

.msg span {
  width: 100%;
  display: inline-block;
}

.msg>div {
  margin: auto;
  width: 100%;
}

.msg img {
  display: inline-block;
  line-height: 20px;
}

.msg.failed {
  color: red;
}

.msg.success {
  color: green;
}

tr.noResults td {
  text-align: center;
}

/* validation */
.TSerror .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.TSwarning .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: orange !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.TSerror .MuiFormHelperText-root.Mui-error {
  color: red !important;
  display: block;
}

.TSwarning .MuiFormHelperText-root.Mui-error {
  color: orange !important;
  display: block;
}

.MuiFormHelperText-root.Mui-error {
  color: black !important;
  display: none;
  font-family: 'Montserrat', sans-serif !important;
}

.mobile.TSerror.MuiFormHelperText-root.Mui-error {
  color: red !important;
}

.mobile.TSwarning.MuiFormHelperText-root.Mui-error {
  color: orange !important;
}

.mobile.MuiFormHelperText-root.Mui-error {
  color: black !important;
  display: none !important;
}

.Stock .mobile.MuiFormHelperText-root {
  text-align: left;
}

.stockTakeForm .selectCell {
  display: none;
}

.table-container.crud .MuiTableRow-root.Mui-selected {
  background-color: #e4ffc6 !important;
}

.table-container.crud .MuiTableRow-root.Mui-selected .MuiTableCell-roo {
  border-bottom: #9ccc65 !important;
}

/* --- stepper header -- */

.stepTitle {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-family: 'Montserrat', sans-serif !important;
  margin-bottom: 8px;
}

.stepTitle>span {
  line-height: 36px;
}

.stepTitle .backBtn {
  float: left;
  text-transform: none;
  font-size: 13px;
}

.stepTitle .clearBtn {
  float: right;
  text-transform: none;
  font-size: 13px;
}

.stepTitle .backBtn,
.stepTitle .clearBtn {
  text-decoration: underline;
  color: #4d4d4d;
}

.stepTitle .backBtn:hover,
.stepTitle .clearBtn:hover {
  background-color: none !important;
}

.removeBtn:hover {
  cursor: pointer;
}

.mobile-nav {
  display: none;
}

.outlet-logo {
  display: none;
  text-align: center;
}

.desktop-logo {
  max-width: 100px;
}

.deviceCount {
  display: none;
}

.outletDetailContainer>h2,
h2.pageTitle {
  display: none;
}

.breadcrumbTitle {
  display: inline-block;
}

.MuiAccordionSummary-content {
  font-weight: bold;
}

h1 {
  font-size: 40px;
  font-weight: 700;
  color: #4d4d4d;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #4d4d4d;
}

h3 {
  font-size: 24px;
  font-weight: normal;
  color: #4d4d4d;
}

.loadUp {
  box-sizing: border-box;
  text-align: center;
  font-size: 32px;
  color: orange;
  text-align: center;
  padding: 20%;
}

.clickerStatic {
  color: #4f4cf3;
  font-weight: 600;
  font-size: 12px;
  margin: 2px 0px 4px 0px;
  float: right;
}

.clickerStatic:hover {
  cursor: pointer;
}

.clickerStatic.productDetail {
  text-align: left !important;
  float: none;
}

.touchsidesAccordianHeader {
  width: 100%;
}

.touchsidesAccordianHeader .MuiAccordionSummary-root {
  display: inline-block;
}

.touchsidesAccordianHeader .clickerStatic {
  line-height: 26px;
}

.Mui-expanded .clickerStatic {
  line-height: 42px !important;
}

.downloadDataModalContainer {
  width: 100%;
  display: inline-block;
}

.appliedFiltersSummary {
  font-size: 12px;
  font-size: #4d4d4d;
  margin: 10px 0;
}

.appliedFiltersSummary span {
  padding: 0 4px;
  color: #d7d7d7;
}

.Stock .floatingBtn {
  position: fixed;
  bottom: 60px;
  right: 75px;
}

/* --- table --- */

.table-container {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  max-height: 84vh;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiTableCell-head,
.MuiTableCell-root,
.MuiTableCell-foot {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
}

.table-total-container thead {
  display: none;
}

.table-container .MuiTableContainer-root {
  box-shadow: none !important;
  max-height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.table-container table thead tr:nth-child(odd) {
  background-color: #ededed;
  font-weight: 500;
}

.table-container table tbody tr:nth-child(odd) {
  font-weight: 400;
}

.table-container table tbody tr:nth-child(even) {
  background-color: #ededed;
  font-weight: 400;
}

.table-container table .MuiTableCell-footer tr {
  position: fixed !important;
  inset-block-end: 0 !important;
  bottom: 0 !important;
}

.table .MuiTableCell-body {
  font-size: 16px !important;
}

table tfoot,
table tfoot th,
table tfoot tr,
table tfoot td {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  bottom: -1px;
  background: #fff;
  z-index: 4;
  font-size: 16px !important;
  font-weight: bold !important;
}

.MuiTypography-root,
.MuiFormGroup-root,
.MuiFormLabel-root,
.MuiOutlinedInput-root,
.MuiButtonBase-root {
  font-family: 'Montserrat', sans-serif !important;
}

/* General Layout */

#root {
  height: 100%;
  min-width: 320px;
}

.app-container {
  min-height: 100%;
}

.app-container .authorised-view {
  width: 100%;
  display: grid;
  grid-template-columns: 240px auto;
  grid-gap: 10px;
  height: 100vh;
  background-image: url('../../public/static/media/bg_desktop.jpg');
  background-color: #ededed;
  background-repeat: no-repeat;
  background-size: contain;
}

.app-container .authorised-view .col-1 {
  background-image: url('../../public/static/media/bg_desktopBottle.png');
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.version-container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: #ededed;
  font-size: 12px;
  color: #4d4d4d;
  z-index: 1000;
}

.col-2 {
  padding: 35px 40px;
}

.content>label {
  display: inline-block;
  font-size: 22px;
  padding-bottom: 10px;
  width: 100%;
  text-align: right;
}

.content>label span {
  font-weight: 600;
}

.content-card {
  padding: 10px 30px 20px 30px;
  min-height: 50%;
  border: 6px solid #e5e5e5;
  border-radius: 18px !important;
}

.nav ul,
.mobilenav ul {
  list-style: none;
  padding: 0;
}

.mobilenav li.myOutlets,
.mobilenav li.signOut {
  padding-left: 30px;
}

.menu li {
  margin-left: 8px;
}

.menu-footer{
  position: fixed;
  bottom: 20px;
}

.menu-footer li{
  margin-left: 8px;
  padding: 0 !important;
}

.nav li {
  padding-left: 10px;
}

.mobilenav .appTitle {
  padding-top: 10px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #4d4d4d;
  text-align: center;
}

.mobilenav ul li.myOutlets a,
.mobilenav ul li.signOut a {
  position: relative;
  line-height: 60px;
  margin-bottom: 10;
  padding-left: 30px;
}

.menu li.myOutlets a:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  display: inline-block;
  background-image: url('../../public/static/media/ico_outletNav.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.mobilenav li.myOutlets a:before {
  top: 0;
}

.menu li.signOut a:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  display: inline-block;
  background-image: url('../../public/static/media/ico_SignoutNav.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.mobilenav li.signOut a:before {
  top: 0;
}

.nav .logo {
  padding: 5px 0 60px 20px;
}

.nav li {
  line-height: 30px;
}

.nav li a {
  position: relative;
  padding: 10px 20px 10px 35px !important;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 60px;
  font-weight: 500;
}

.menu-footer li a.primary-link-small {
  font-size: 14px; 
}

h2 {
  text-transform: uppercase;
}

.select-container {
  display: inline-block;
}

/* Outlets */

.outletList {
  margin-top: 40px;
}

.AddOutletActionPanel {
  display: inline-block;
}

.blockButton {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  padding: 12px 50px;
  border-radius: 10px;
  margin-right: 1%;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  box-shadow: 0px 5px 5px #00000052;
}

.blockButton:hover {
  cursor: pointer;
}

.blockButton.outletEntry:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 60px;
  background-image: url('../../public/static/media/icon_outletDesktop.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.blockButton.action {
  background-color: white;
  border: 2px solid #ccc;
}

.blockButton.action:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 60px;
  background-image: url('../../public/static/media/ico_addBtn.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.blockButton.outletEntry {
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blockButton.outletEntry:nth-child(3n + 1) {
  background-color: #fff1a8;
  border: 1px solid #ffd700;
}

.blockButton.outletEntry:nth-child(3n + 2) {
  background-color: #e5a3a5;
  border: 1px solid #d0585e;
}

.blockButton.outletEntry:nth-child(3n + 3) {
  background-color: #ffc7a0;
  border: 1px solid #ffa300;
}

/* Outlet Detail */
.blockButton--static {
  position: relative;
  width: 400px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 50px;
  background-repeat: no-repeat;
  background-position: right;
  padding: 25px 15px;
  box-sizing: border-box;
}

.blockButton--static:before {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 22px;
  top: 25px;
}

.blockButton.cashUp {
  background-color: #ffc7a0;
  border: 1px solid #ff6900;
  background-image: url('../../public/static/media/bg_cashUpBtn.png');
}

.blockButton.cashUp:before {
  background-image: url('../../public/static/media/ico_cashUpBtn.png');
}

.blockButton.stockOnHand {
  background-color: #e5a3a5;
  border: 1px solid #a5171e;
  background-image: url('../../public/static/media/bg_stockOnHandBtn.png');
}

.blockButton.stockOnHand:before {
  background-image: url('../../public/static/media/ico_stockOnHandBtn.png');
}

.blockButton.stockTake {
  background-color: #ffb9a7;
  border: 1px solid #fa4616;
  background-image: url('../../public/static/media/bg_stockTakeBtn.png');
}

.blockButton.stockTake:before {
  background-image: url('../../public/static/media/ico_stockTakeBtn.png');
}

.blockButton.addStock {
  background-color: #ffd996;
  border: 1px solid #ffa300;
  background-image: url('../../public/static/media/bg_addStockBtn.png');
}

.blockButton.addStock:before {
  background-image: url('../../public/static/media/ico_addStockBtn.png');
}

a {
  color: #4d4d4d;
  text-decoration: none;
}

a:hover {
  color: #db1f26;
}

[aria-current] {
  text-decoration: underline;
}

/* Dialog */

.MuiDialogTitle-root {
  text-transform: initial;
  font-family: 'Montserrat', sans-serif !important;
  text-align: left !important;
  padding-left: 19px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.MuiDialogContent-root {
  width: 100%;
  padding: 0 10px 10px 10px !important;
  box-sizing: border-box;
}

.MuiDialogActions-root {
  padding: 15px 8px !important;
}

/* Outlet Detail */
.outletDetailContainer h3 {
  font-size: 22px;
  font-weight: 700;
  color: #4d4d4d;
  margin: 10px 0;
}

.outletDetailContainer h3.first {
  margin-top: 12px !important;
}

.firstCrumb h2 {
  font-weight: normal !important;
}

.firstCrumb h2:hover {
  text-decoration: underline;
}

/* banner and cookies page */

.legal-banner {
  position: fixed;
  bottom: 100px;
  left: 4%;
  right: 4%;
  bottom: 4%;
  width: auto;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  border-color: #e77108;
  background-color: #eee;
  display: flex;
  font-size: 14px;
  z-index: 100;
}

.legal-banner:hover {
  box-shadow: -4px 4px 3px rgba(0, 0, 0, 0.2);
}

.legal-banner-close-button {
  float: right;
  padding: 4px 12px;
  background: #ddd;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16pt;
}

.legal-banner-close-button:hover {
  background: #000;
  color: #ffffff;
}

.cookies-header {
  font-weight: bold;
  font-size: x-large;
  color: #4d4d4d;
  font-family: 'Montserrat', sans-serif !important;
}

.cookies-heading {
  font-weight: bold;
  font-size: medium;
  color: #4d4d4d;
  font-family: 'Montserrat', sans-serif !important;
}

.page-container-policy .p {
  font-family: 'Montserrat', sans-serif !important;
}

.page-container-policy {
  background-size: contain;
  background-position: center center;
  background-color: #ededed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 20px;
}

.sub-point {
  font-size: large;
  font-weight: 400;
  display: inline-block;
}

.header-container {
  text-align: center;
}

.cookies-and-terms-body {
  padding: 2px;
  margin-left: 10px;
  margin-top: 30px;
}

.semibold-text {
  font-weight: 600;
  font-size: 24px;
}

.faqs{
  margin-top: 10px;
  margin-left: 80px;
}


.faqs svg {
  margin-right: 5px;
  margin-bottom: -5px;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  margin: auto;
  box-sizing: border-box;
}

.faq-beforeSignIn{
  width: 500px;
  height: 500px;
}

.faq-touchsides{
  margin-top: auto;
}

.listItemSelected{
  border: 1px solid #ddd;
  margin-bottom: 20px;
}


.listItems{
  background-color: white;
}

.MuiList-root {
  display: flex;
  flex-direction: column;
}

.faq-container h6 {
  text-align: center;
}

.touchsides-tel{
  color: #00D03A;
}

.header-container {
  text-align: center;
}

.cookies-body {
  padding: 50px;
}

.mobile-logo-cookies,
h1 {
  display: inline-block;
  vertical-align: middle;
}

.mobile-logo-cookies {
  display: inline-block;
  width: 90px;
  height: 90px;
  vertical-align: middle;
}

.primary-link {
  color: blue;
}

.warning-message{
  color: orange;
}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: left;
}

.no-data-message svg {
  margin-right: 10px;
}

.no-data-message p {
  margin: 0;
}

.no-data-message p:first-child {
  font-weight: bold;
}


.link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.separator {
  margin: 0 5px;
  color: blue;
}

.primary-link-small {
  color: blue;
  font-size: 12px;
}

.cookies-footer {
  text-align: right;
  padding: 20px;
}

.PoweredByTouchsides {
  display: inline-block;
}

.PoweredByTouchsides img {
  width: 150px;
  height: auto;
}

.cookies-and-terms-header {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  font-size: x-large;
  color: #4d4d4d;
  font-family: 'Montserrat', sans-serif !important;
}

.custom-ol-style {
  counter-reset: item;
  font-size: large;
  padding-left: 4px;
}

li {
  display: block;
  font-weight: 400;
  margin-top: 20px;
}

.custom-ol-style li::before {
  content: counters(item, '.') '. ';
  counter-increment: item;
}

.custom-ol-style .alphabetical {
  counter-reset: item;
  padding-left: 60px;
}

.custom-ol-style .alphabetical li {
  display: block;
  font-weight: 400;
  position: relative;
}

.custom-ol-style .alphabetical li::before {
  content: counter(item, lower-alpha) ') ';
  counter-increment: item;
  position: absolute;
  left: -30px;
}

.custom-list {
  list-style-type: disc;
  padding-left: 20px;
}

/* Device List */
.deviceList,
.deviceCount {
  padding: 8px;
  border: 2px solid #a7a9aa;
  border-radius: 8px;
  margin-bottom: 25px;
  max-height: 300px;
  overflow-y: auto;
}

.deviceEntry {
  margin-right: 10px;
}

.deviceEntry .description,
.deviceEntry .updated {
  position: relative;
  display: inline-block;
  font-size: 18px;
  padding: 8px 20px 8px 8px;
}

.deviceEntry .description {
  width: 40%;
}

.deviceEntry .deviceIconContainer .deviceIcon {
  content: '';
  position: absolute;
  right: 8%;
  top: 5px;
  display: block;
  width: 30px;
  height: 30px;
  background: no-repeat;
  background-size: contain;
}

.deviceEntry .deviceIconContainer .deviceIcon {
  cursor: pointer;
}

.deviceEntry.Error .deviceIconContainer .deviceIcon {
  background-image: url('../../public/static/media/ico_deviceRed.png');
}

.deviceEntry.Warning .deviceIconContainer .deviceIcon {
  background-image: url('../../public/static/media/ico_deviceAmber.png');
}

.deviceEntry.Ok .deviceIconContainer .deviceIcon {
  background-image: url('../../public/static/media/ico_deviceGreen.png');
}

.deviceEntry .updated {
  width: 45%;
  padding-left: 40px;
}

.deviceEntry.Error .updated {
  color: #db1f26;
}

.deviceEntry.Warning .updated {
  color: #ff6900;
}

.deviceEntry.Ok .updated {
  color: #0c6b0c;
}

.deviceList .note {
  padding: 12px 10px;
  font-size: 16px;
}

/* Device Count */

.deviceCount {
  position: relative;
  background-image: url('../../public/static/media/ico_arrow.png');
  background-repeat: no-repeat;
  background-position: 95%;
  padding-right: 20%;
  box-sizing: border-box;
}

.deviceCount .title {
  font-size: 14px;
  display: inline-block;
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 6px;
}

.deviceCount .count {
  font-size: 14px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 5px;
  float: right;
}

.deviceCount .devicesRequiringAttention {
  font-size: 11px;
  padding-bottom: 8px;
  padding-left: 6px;
}

.deviceCount .devicesRequiringAttention.onePlus {
  color: #db1f26;
  font-style: italic;
  font-weight: 500;
}

.deviceCount .devicesRequiringAttention.zero {
  color: #0c6b0c;
  font-weight: 500;
}

/* DEVICE DIALOG */
.MuiDialogContent-root .description {
  width: 100%;
  font-size: 14px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.MuiDialogContent-root .description:before {
  content: '';
  position: absolute;
  right: 30%;
  top: 9px;
  display: block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}

.MuiDialogContent-root .updated {
  padding-left: 9px;
  width: 100%;
  font-size: 12px;
  padding-top: 0;
  box-sizing: border-box;
}

.MuiDialogContent-root p {
  font-size: 13px;
  box-sizing: border-box;
  padding: 0px 9px;
}

.Mui-expanded .OpenIndicator {
  transform: rotate(90deg);
}

/* ---- Form ---- */

.basicSelect,
.datePickers,
.actionBtn {
  display: inline-block;
}

.submitBtn {
  background-color: #ffa300;
  color: #4d4d4d;
  font-size: 18px;
  border-radius: 25px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: initial;
  padding: 5px 20px;
}

/* Add a hover rule to maintain the background color on hover */
.submitBtn:hover {
  background-color: #ffa300;
}

.actionBtn {
  margin-top: 20px !important;
  font-size: 15px !important;
}

.basicSelect .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-formControl {
  background-color: white !important;
  padding: 0 5px !important;
}

.bottomBar {
  display: block !important;
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.basicSelect.bottom {
  position: relative;
  float: left;
  margin: 10px 3px;
}

.pagination {
  position: relative;
  float: right;
  margin: 10px 3px;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: orange !important;
}

.MuiInputBase-colorPrimary {
  border-radius: 20px !important;
  margin-right: 5px;
}

.basicSelect .MuiInputBase-input {
  padding: 11px 14px !important;
  padding-right: 40px !important;
}

.search-dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-select {
  display: flex;
  flex-direction: row;
}

.single-select{
  min-width: 230px;
  min-height: 42px;
}

.MuiInputBase-adornedStart {
  min-width: 430px !important;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

.button-container__text {
  display: flex;
  margin-bottom: 5px;
}

.button-container__buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 10px;
}

.datePickers .MuiInputBase-input {
  padding: 11px 0 11px 14px !important;
}

.stockOnHandProdDetail {
  line-height: 30px;
  margin: 10px;
  width: 250px;
}

.table-pagination-select {
  margin-bottom: 12px;
}

.MuiTablePagination-actions {
  position: relative;
  bottom: 7px;
}

/* ---- Download dialog ---- */

.fieldSet {
  width: 48%;
  display: inline-block;
  margin: 0 1% 20px 1%;
  box-sizing: border-box;
}

.exportDialog {
  max-width: 400px;
  margin: 8px;
  font-family: 'Montserrat', sans-serif !important;
}

.exportDialog .title {
  font-weight: 500;
}

.exportDialog .subTitle {
  font-weight: 500;
}

.exportDialog .labelContainer {
  width: 100%;
}

.exportDialog .labelContainer span {
  font-weight: 700;
}

.exportDialog .msg {
  font-size: 16px;
  padding: 10px 0;
  line-height: 24px;
}

.exportDialog .success {
  color: #0c6b0c;
}

.exportDialog label.MuiFormControlLabel-root {
  margin: 5px 0 5px 0;
}

.bigBlue {
  color: blue;
  text-decoration: underline;
}

.bigBlue:hover {
  color: rgb(24, 66, 232);
}

/* ---------------
UNAUTHORISED VIEW - Getting Started 
-------------------------------------*/

.app-container .unauthorised-view,
.not-found-view,
.login {
  width: 100%;
  height: 100vh;
  background-image: url('../../public/static/media/bg_unauth.jpg');
  background-size: contain;
  background-position: center center;
  background-color: #ededed;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tile {
  width: 300px;
  padding: 40px 50px 80px 50px;
  background-color: white;
  border: 1px solid #fafafa;
  text-align: center;
}

.tile .logo {
  margin: 40px 0;
}

Button.MuiButton-containedPrimary {
  background-color: #ffa300 !important;
  color: #4d4d4d !important;
  font-size: 18px;
  border-radius: 25px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: initial !important;
  padding: 5px 20px;
}

Button.MuiButton-containedPrimary:hover {
  background-color: #ffa600 !important;
}

Button.MuiButton-containedPrimary:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgb(205, 205, 205) !important;
}

Button.MuiButton-outlinedPrimary {
  border: 2px solid #ffa300 !important;
  color: #4d4d4d !important;
  font-size: 18px;
  border-radius: 25px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: initial !important;
  padding: 4px 20px;
}

Button.MuiButton-outlinedPrimary:hover {
  border: 2px solid #ffa300 !important;
}

.button-progress {
  color: white;
}


.tile Button {
  width: 100%;
  margin-top: 30px;
  font-size: 20px;
  text-transform: initial;
  font-weight: 600;
}

.unauthorised-view .PoweredBy {
  display: none;
}

/* Stock take Page styling */

.noItemsSelected{
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  min-height: 72vh; 
  max-width: 660px;
  margin: 0 auto;
}

.noItemsSelected p {
  text-align: center;
  padding: 10px 100px;
}
.MuiTabs-indicator {
  background-color: orange;
}

.MuiBadge-badge {
  background-color: #67E98B;
  color: #FFFF; 
  padding: 0 6px; 
  min-width: 20px; 
  border-radius: 50%; 
  font-size: 12px; 
}

.stock-tabs {
  margin-bottom: 10px;
  border-width: 100%;
  justify-content: center;
  background-color: #F9FAFB; 
  width: fit-content;
  height: 1px;
}

.stock-tab-label {
  color: #333 !important; 
}

.border-line {
  border-bottom: 1px solid #EBE1D9;
  margin-bottom: 20px; 
}

// Stock add page enhancement 
.noStockSelected{
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  min-height: 72vh; 
  max-width: 660px;
  margin: 0 auto;
}


.noStockSelected p {
  text-align: center;
  padding: 10px 100px;
}



.stock-add-tabs {
  margin-bottom: 10px;
  border-width: 100%;
  justify-content: center;
  background-color: #F9FAFB; 
  width: fit-content;
  height: 1px;
}

.stock-add-tab-label {
  color: #333 !important; 
}



/* ---------------
RESPONSIVE
-------------------------------------*/

@media only screen and (max-width: 1500px) {
  .breadcrumbTitle {
    margin: 15px 0;
  }

  .MuiBreadcrumbs-li h2 {
    font-size: 22px;
    margin: 0;
  }

  .faq-container{
    padding: 10px;
  }
  
  .faq-beforeSignIn {
    width: 100%;
    height: 400px; 
    max-width: 300px;
  }
  
  .semibold-text{
    font-size: 18px;
  }

}

@media only screen and (max-width: 1280px) {
  .blockButton--static {
    width: 48%;
    margin-right: 2%;
  }

  .breadcrumbTitle {
    margin: 15px 0;
  }

  .MuiBreadcrumbs-li h2 {
    font-size: 18px;
    margin: 0;
  }
}

@media only screen and (max-width: 1120px) {
  .MuiBreadcrumbs-li h2 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1025px) {
  .MuiAccordionSummary-root {
    display: none !important;
  }

  .revealMe,
  .TSaccordianTitle,
  .touchsidesAccordianHeader .clickerStatic,
  .appliedFiltersSummary {
    display: none !important;
  }

  .mobileElem {
    display: none !important;
  }

  .productUpdateContainer label {
    display: none;
  }

  .productUpdateContainer .prodName {
    padding-left: 15px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .TSerror .MuiFormHelperText-root.Mui-error {
    color: red !important;
  }

  .TSwarning .MuiFormHelperText-root.Mui-error {
    color: orange !important;
  }

  .MuiFormHelperText-root.Mui-error {
    color: black !important;
    display: none !important;
  }

  .mobile.TSerror.MuiFormHelperText-root.Mui-error {
    color: red !important;
    display: inline-block !important;
  }

  .mobile.TSwarning.MuiFormHelperText-root.Mui-error {
    color: orange !important;
    display: inline-block !important;
  }

  .mobile.MuiFormHelperText-root.Mui-error {
    color: black !important;
    display: none !important;
    padding: 12px 0;
    width: 100%;
  }

  .Stock .MuiInputBase-input:not(.MuiTablePagination-select) {
    font-size: 18px;
  }

  .updatedLegend {
    display: inline-block;
    float: none;
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }

  .stepTitle {
    margin-bottom: 0;
  }

  .stepTitle .backBtn,
  .stepTitle .clearBtn {
    background-color: #fafafa;
  }

  .stepTitle .backBtn span {
    visibility: hidden;
  }

  .stepTitle .backBtn:before {
    content: '';
    display: inline-block;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACxSURBVHgB7dOxDQIhFAbgx+kl17qBJWEKSwpC3MA4giM4gSN4I2igP1awgy1IaAkROmPkgGu9vyL54QvJA4D/DqV0zxg7tpzpcsUwDIAQunHOT1CZTa7QWltCyDOEMGKMrTHmBUuxJeAs1goWsRYQQUPidA9xKJNzbqeUst99VwulpxKhe1yef0HVN0tQ3/dTXF6FEGNuXxGrhYpYCzSLtUIp21yRvpP3/iKlfMCaz7wBq6tfwe1fMaEAAAAASUVORK5CYII=');
    transform: rotate(-180deg);
    background-repeat: no-repeat;
    height: 16px !important;
    width: 16px !important;
    position: absolute;
    left: 24px !important;
    top: 11px !important;
  }

  .stockContain .stepPrimary {
    display: block;
    position: fixed;
    right: 30px;
    bottom: 30px;
  }

  .stepTitle span {
    font-size: 13px;
  }

  .mobile.stockFormRow,
  .productUpdateContainer {
    padding: 8px 15px;
    position: relative;
    display: inline-block !important;
    width: 100%;
    box-sizing: border-box;
  }

  .productUpdateContainer th,
  .productUpdateContainer td {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    border-bottom: none;
    text-align: left;
    padding: 0;
    color: black;
    -webkit-flex-direction: none;
    -ms-flex-direction: none;
    flex-direction: none;
  }

  .mobile.prodName,
  .mobile.barcode,
  .productUpdateContainer .prodName,
  .productUpdateContainer .barcode {
    display: block;
  }

  .productUpdateContainer .prodName {
    font-size: 14px !important;
    font-weight: 500;
  }

  .productUpdateContainer .barcode {
    font-size: 12px !important;
    color: #929598;
  }

  .mobile.remove,
  .productUpdateContainer .removeButton {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .mobile.remove:hover,
  .productUpdateContainer .removeButton:hover {
    cursor: pointer;
  }

  .mobile.quantity,
  .productUpdateContainer .quantityContainer span {
    font-size: 20px;
    font-weight: 500;
    background-color: lightgray;
    padding: 4px 5px;
    min-width: 50px;
    border-radius: 5px;
    float: left;
    text-align: center;
    margin: 5px 0;
  }

  .mobile.quantity,
  .productUpdateContainer:nth-child(even) .quantityContainer span {
    background-color: white;
  }

  .productUpdateContainer .quantityContainer label {
    float: left;
    margin-left: 3px;
    margin-right: 10px;
  }

  .productUpdateContainer label {
    font-size: 12px;
    color: #929598;
    min-width: 30px;
    margin-top: 7px;
  }

  .productUpdateContainer .quantityContainer {
    float: left;
    margin-top: 10px;
  }

  .productUpdateContainer .fieldContainer {
    float: right;
    margin-top: 10px;
  }

  .productUpdateContainer .fieldContainer label {
    float: right;
    text-align: right;
    font-size: 12px;
  }

  .adjustField,
  .productUpdateContainer .fieldContainer .adjustField {
    float: right;
    margin-left: 3px;
    min-width: 30px;
    max-width: 80px;
    width: auto;
    margin-top: 5px !important;
  }

  .MuiTable-root {
    min-width: auto !important;
  }

  .desktopElem {
    display: none !important;
  }

  .prodName {
    font-weight: 500;
    font-size: 13px;
    font-family: 'Montserrat';
  }

  .barcode {
    font-size: 12px;
    font-family: 'Montserrat';
    color: #666;
  }

  .appliedFiltersSummary.show-me {
    display: block;
  }

  .appliedFiltersSummary.hide-me {
    display: none;
  }

  .unauthorised-view .PoweredBy {
    display: block;
  }

  .outlet-logo {
    display: block;
  }

  .revealMe {
    display: table-cell;
  }

  .hideMe {
    display: none !important;
  }

  .filter-container .MuiPaper-root {
    box-shadow: 0 0 0 0 !important;
    border-bottom: 1px solid rgb(87, 87, 87) !important;
  }

  .basicSelect.full,
  .datePickers.full {
    width: 100% !important;
  }

  .basicSelect .MuiFormControl-root {
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .datePickers .MuiFormControl-root {
    width: 72%;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .hideMe {
    display: none !important;
  }

  .downloadDataModalContainer.filteredPage {
    display: none;
  }

  h2.pageTitle {
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  .app-container .authorised-view {
    display: block;
    background-image: url('../../public/static/media/bg_mobile.png');
    background-size: cover;
  }

  .MuiDrawer-root {
    display: block !important;
  }

  .mobile-nav {
    display: block !important;
  }

  .mobile-nav>div {
    display: inline-flex;
  }

  .mobile-nav img {
    display: inline-block;
  }

  .mobile-nav .mobile-logo {
    position: absolute;
    margin-left: auto;
    margin-top: 10px;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .mobile-nav .mobile-signout {
    float: right;
    margin-top: 10px;
  }

  .mobile-nav .mobile-signout:hover {
    cursor: pointer;
  }

  .app-container .authorised-view .col-1 {
    display: none;
  }

  .app-container .authorised-view .col-2 {
    padding-top: 0;
  }

  .content>label {
    display: none;
  }

  .content-card {
    padding: 10px 20px 40px 20px;
  }

  .clicker {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }

  .clicker:hover {
    cursor: pointer;
  }

  .deviceList {
    display: none;
  }

  .deviceCount {
    display: block;
  }

  h1 {
    font-size: 40px;
    font-weight: 700;
    color: #4d4d4d;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #4d4d4d;
    text-align: center;
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    color: #4d4d4d;
  }

  .hamburger-menu img {
    margin: 10px;
  }

  .outletDetailContainer h2 {
    font-size: 18px;
    display: block;
    text-align: center;
  }

  .outletDetailContainer h3 {
    font-size: 16px;
    margin: 8px 0;
  }

  .breadcrumbTitle {
    display: none;
  }

  .blockButton {
    font-size: 12px;
    display: inline-block;
    min-width: 47%;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 12px 20px;
  }

  .AddOutletActionPanel {
    min-width: 47%;
  }

  .blockButton.action {
    padding: 12px 20px !important;
    width: 100%;
  }

  .blockButton.outletEntry:before,
  .blockButton.action:before {
    height: 45px;
  }

  .app-container .unauthorised-view {
    background-image: url('../../public/static/media/bg_unauth.jpg');
    background-size: 200%;
  }

  .privacy-policy {
    display: none;
  }

  .terms-and-conditions {
    display: none;
  }

  .PoweredBy {
    display: none;
  }

  .mobile-terms-and-conditions {
    position: absolute;
    bottom: 150px;
    left: 30px;
  }

  .mobile-privacy-policy {
    position: absolute;
    bottom: 120px;
    left: 30px;
  }

  .PoweredByNav {
    position: absolute;
    bottom: 50px;
    left: 30px;
  }
}

   
@media only screen and (max-width: 980px) {
  .search-dropdown-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
  }

  .dropdown-select {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: first baseline;
    margin-right: 6px;
    gap: 20px;
  }

  .button-container{
    margin-left: 10px;
  }

  .single-select {
    width: 428px;
  }
  
  .autocomplete {
    width: 428px;
  }

}

@media only screen and (max-width: 765px) {
  
  .noItemsSelected {
    min-width: 100px;
    min-height: 40vh;
    margin-top: 60px;
  }
  
  .noItemsSelected p {
    text-align: center;
    padding: 10px 30px;
  }

  .noStockSelected {
    min-width: 100px;
    min-height: 40vh; /* Adjust padding for smaller screens */
  }
  
  .noStockSelected p {
    text-align: center;
    padding: 10px 30px;
  }

  .stepTitle .backBtn:before {
    left: 17px !important;
    top: 7px !important;
  }

  .stepTitle .backBtn {
    float: left;
    text-transform: none;
    font-size: 11px;
    padding: 2px;
    min-width: 50px;
  }

  .stepTitle span {
    font-size: 12px !important;
    line-height: 22px !important;
    font-weight: 500;
  }

  .stepTitle .clearBtn {
    float: right;
    text-transform: none;
    font-size: 11px;
    padding: 2px;
  }

  .app-container .unauthorised-view {
    background-image: none;
    background-color: white;
  }

  .unauthorised-view .PoweredBy {
    margin-top: 20px;
  }

  .unauthorised-view .tile {
    box-shadow: none;
    border: none;
  }

  .col-2 {
    padding: 10px;
  }

  .blockButton {
    width: 48px;
    line-height: 16px;
    margin-left: 1%;
    margin-right: 1%;
  }

  .tile {
    box-sizing: border-box;
    margin: 5px;
    width: auto;
    max-width: 300px;
    padding: 20px 50px 50px 50px;
    text-align: center;
  }

  .blockButton--static {
    width: 100%;
    font-size: 16px;
    padding: 10px 15px;
  }

  .blockButton--static:before {
    width: 40px;
    height: 40px;
    right: 6px;
    top: 15px;
  }

  .blockButton.cashUp {
    background-image: url('../../public/static/media/bg_cashUpMobile.png');
  }

  .blockButton.stockOnHand {
    background-image: url('../../public/static/media/bg_stockOnHandMobile.png');
  }

  .blockButton.stockTake {
    background-image: url('../../public/static/media/bg_stockTakeMobile.png');
  }

  .blockButton.addStock {
    background-image: url('../../public/static/media/bg_addStockMobile.png');
  }
}

@media only screen and (min-width: 729px) {

  .PoweredBy {
    left: 4px;
  }

  .terms-and-conditions {
  //  bottom: 30px;
  }

  .login .PoweredBy {
    right: 30px;
    text-align: right;
  }
}

@media only screen and (max-width: 500px) {

  .FeSearchFilter .MuiOutlinedInput-input,
  .FeSearchFilter label.MuiInputLabel-root {
    font-size: 13px !important;
  }

  .MuiInputBase-adornedStart {
    min-width: 380px !important;
    height: 40px;
  }

  .button-container__buttons {
    margin-left: 8px;
  }
  
  .Stock .MuiInputBase-input {
    padding: 4px 14px !important;
    font-size: 12px;
  }
 
  .table-pagination-select {
    padding: 1px;
  }

  .FeSearchFilter .MuiInputBase-input {
    padding: 7px 14px !important;
  }

  .productUpdateContainer .fieldContainer label {
    float: right;
    text-align: right;
    font-size: 9px;
  }

  .fieldSet {
    width: 48% !important;
    display: inline-block;
    margin: 0 1% 12px 1% !important;
    box-sizing: border-box;
    font-size: 14px;
  }

  .exportDialog .subTitle {
    font-size: 14px;
  }

  .MuiRadio-root {
    padding-left: 0 !important;
  }

  .pagination {
    margin: auto !important;
    width: 100% !important;
  }

  .productUpdateContainer .prodName {
    font-size: 12px !important;
    font-weight: 500;
  }

  .productUpdateContainer .quantityContainer span {
    font-size: 12px;
    min-width: 25px;
  }

  .mobile.stockFormRow,
  .productUpdateContainer {
    padding: 8px 10px;
  }

  .productUpdateContainer .quantityContainer label {
    float: left;
    width: auto;
    font-size: 10px;
    margin-left: 2px;
    margin-right: 8px;
  }

  .productUpdateContainer label {
    color: #929598;
    min-width: 30px;
    margin-top: 4px;
  }

  .dropdown-select{
    margin-right: 4px;
    width: 100%;
  }

  .autocomplete{
   min-width: 370px;
   max-width: 380px;
  }

  .single-select{
    min-width: 370px !important;
    max-width: 380px;
  }
}

@media only screen and (max-width: 440px) {
  .Stock .MuiInputBase-input {
    padding: 4px 14px !important;
  }

  .FeSearchFilter .MuiInputBase-input {
    padding: 7px 14px !important;
  }

  .MuiInputBase-adornedStart {
    min-width: 350px !important;
  }

  .productUpdateContainer .fieldContainer label {
    float: right;
    text-align: right;
    font-size: 10px;
  }

  .stepPrimary.Next:after {
    top: 10px;
    position: absolute;
    background-size: contain;
    height: 15px !important;
    width: 16px !important;
  }

  .blockButton.outletEntry {
    width: 100%;
    max-width: 100%;
    line-height: 30px;
  }

  .fieldSet {
    width: 98% !important;
  }

  .exportDialog .msg,
  .exportDialog label.MuiFormControlLabel-root .labelContainer {
    font-size: 13px !important;
  }

  .stockOnHandProdDetail {
    font-size: 14px;
    line-height: 20px;
    margin: 0 8px !important;
    width: 215px !important;
  }

  Button.MuiButton-containedPrimary,
  Button.MuiButton-outlinedPrimary {
    font-size: 14px;
  }

  .pagination .MuiPaginationItem-root {
    font-size: 12px;
    padding: 0px 3px;
    margin: 0 3px;
    min-width: 22px;
    height: 22px;
  }

  .pagination .MuiPaginationItem-ellipsis {
    margin: 0;
    padding: 0;
    min-width: 18px;
  }

  .pagination .MuiPaginationItem-previousNext {
    margin: 0 1px;
  }

  .dropdown-select{
    margin-right: 6px;
    width: 100%;
  }

  .autocomplete{
   min-width: 100px;
   max-width: 348px;
  }

  .single-select{
    min-width: 100px !important;
    max-width: 348px;
  }

  .MuiTableRow-head th,
  .MuiTableRow-head td,
  .MuiTableRow-root th,
  .MuiTableRow-root td,
  .MuiTableRow-footer th,
  .MuiTableRow-footer td {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 400px) {

  .FeSearchFilter .MuiOutlinedInput-input,
  .FeSearchFilter label.MuiInputLabel-root {
    font-size: 12px !important;
  }

  .Stock {
    margin: 0 -19px;
  }

  .autocomplete{
    max-width: 260px;
  }

  .single-select{
    max-width: 260px !important;
  }

  .Stock .search {
    margin: 0 10px;
  }

  .MuiInputBase-adornedStart {
    min-width: 260px !important;
  }

  .deviceEntry .deviceIconContainer .deviceIcon {
    right: 0 !important;
    width: 25px;
    height: 25px;
  }

  .MuiDialogTitle-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTableRow-head th,
  .MuiTableRow-head td,
  .MuiTableRow-root th,
  .MuiTableRow-root td,
  .MuiTableRow-footer th,
  .MuiTableRow-footer td {
    font-size: 12px !important;
  }

  .prodName {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat';
  }

  .barcode {
    font-size: 11px;
    font-family: 'Montserrat';
    color: #666;
  }

  .MuiTablePagination-root>p {
    margin: auto;
  }
}