@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/* body background */
body {
  background-color: #f9f9f9;
  /* background: linear-gradient(to top right,#F4A21D,#f9f9f9); */
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: currentColor;
  /* 2 */
}

/* Container for pages with header and triple bottom border */
.widget {
  position: relative;
  clear: both;
  width: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.55);
}

.signin-container {
  min-width: 350px;
  max-width: 450px;
  display: block;
  margin: 40px auto 20px auto;
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.55);
  padding: 16px 28px 23px;
}

.signin-container h1 {
  margin-bottom: 0.4em;
  color: #f90;
  font-size: 32px;
  font-family: 'Open Sans';
  font-weight: normal;
}

.signin-container p {
  color: #838383;
  font: 13px/1.7em 'Open Sans';
}

.login-extra {
  display: block;
  margin: 1.5em auto;
  text-align: center;
  line-height: 19px;
  text-shadow: 1px 1px 0px #fff;
}


.cookies-heading{
  margin-bottom: 10px; 
}

.cookies-heading p{
  margin-bottom: 10px;
}

.cookies-header {
  margin-bottom: 10px;
}

.cookies-body{
  margin-bottom: 10px;
}

.primary-link{
  color: blue;
}

.paging-bottom{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 456px) {
  .phoneSpace {
    margin-top: 30px !important;
  }
}

.widgetHeader {
  position: relative;
  height: 40px;
  line-height: 40px;
  /*border: 1px solid #D5D5D5;
    background: -webkit-linear-gradient(top, #fafafa 0%, #e9e9e9 100%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;*/
  box-sizing: border-box;
}

.widgetHeader h3 {
  top: 0;
  position: relative;
  left: 10px;
  display: inline-block;
  margin-right: 3em;
  font-size: 18px;
  font-weight: 600;
  color: #555;
  line-height: 21px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.widgetStacked {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  position: relative;
  box-sizing: border-box;
  clear: both;
  /*    border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    background-color: white;
    border: 1px solid #D5D5D5;
    border-top: none;
    */
}

/*    .widgetStacked:after, .widgetStacked:before {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        border-radius: 0 0 3px 3px;
        border-top: 0;
        background-color: #ffffff;
        border-bottom: 1px solid #d3d3d3;
        height: 1px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .widgetStacked:before {
        bottom: -5px;
        left: 6px;
        right: 6px;
    }

    .widgetStacked:after {
        bottom: -3px;
        left: 3px;
        right: 3px;
    }*/

/* Orange hover, borderless button elements */
.ui.list.bootstraplikemenu .item a.ui.basic.button {
  box-shadow: none !important;
  text-align: left;
  transition: background-color 0.3s;
}

.ui.list.bootstraplikemenu .item a.ui.basic.button:hover {
  background: orange !important;
  transition: background-color 0.3s;
}

.ui.list.bootstraplikemenu .item:not(:last-child) {
  border-bottom: 1px solid #eee !important;
}

.mainbutton .selected {
  color: orange;
}

/* make react data grid look more like semantic ui table */
.react-grid-Container {
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.react-grid-Main {
  outline: none !important;
}

.react-grid-Grid {
  border: none;
}

.ui.tabular.menu .active.item {
  border-top: 3px solid #ff9900;
}

/* bootstrap style nav tabs */
.nav-tabs {
  border-bottom: 1px solid #ddd;
  padding-left: 0;
  margin-bottom: 20px;
  list-style: none;
  display: block;
}

.nav-tabs:after,
.nav-tabs:before {
  display: table;
  content: ' ';
}

.nav-tabs::after {
  clear: both;
}

.nav-tabs li {
  float: left;
  margin-bottom: -1px;
  position: relative;
  display: block;
}

.nav-tabs li a {
  color: #555;
  border-top-width: 3px;
  padding: 7px 12px;
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  position: relative;
  display: block;
}

.nav-tabs li.active a {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: #fff;
  border-top: 3px solid #ff9900;
}

.nav-tabs li.active a:hover {
  background-color: transparent;
  border-bottom-color: #fff;
  bottom: 0px;
}

.nav-tabs li a:hover {
  background-color: #eee;
  bottom: -1px;
}

/* File upload */
.ui.action.file.input>input {
  display: none;
}

.ui.action.file.input:not([class*='left action'])>label {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-color: transparent !important;
}

.ui.action.file.input>label:first-child {
  cursor: pointer;
  width: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.67861429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

.ui.action.file.input>label:last-child {
  border-radius: 0 0.28571429rem 0.28571429rem 0 !important;
}

/* for top menu mainly, but all scroll bars */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 149, 43, 0.3);
  border: 2px solid rgba(255, 149, 43, 0.3);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 149, 43, 0.7);
  border: 2px solid rgba(255, 149, 43, 0.7);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(255, 149, 43, 0.7);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: none;

  background: transparent;
  /*border: 0px solid #eee;*/
  border-radius: 50px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

@media (min-width: 600px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-width: 5px;
  }
}

.innermodalcontents {
  overflow-x: visible !important;
}

/* horizontal scrolling on mobile */
.innermodalcontents,
.box-body {
  overflow-x: auto;
}

@media (max-width: 600px) {
  .innermodalcontents {
    padding-left: 0.5em;
  }
}

/* modal window experiment */
.animatedbackground {
  transition: background-color 0.5s ease;
  /*transition: all 0.5s ease; messes with chartjs trying to resize*/
}

.modalfullscreenbackground {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  display: table;
  padding: 1em;
}

/* .modalcontents {
  display: table-cell;
  vertical-align: middle;
  max-width: 95%;
  max-height: 95%;
  overflow-y: auto;
} */

.modalfullscreenbackground .innermodalcontents {
  border-radius: 10px;
  border: 10px solid white;
  background: white;
}

.demofullscreenbackground {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  display: table;
  padding: 1em;
  /* background-image: url('/Content/img/beerbackground.jpg'); */
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  overflow: hidden;
}

.demofullscreenbackground .modalcontents {
  overflow: hidden;
}

.demofullscreenbackground .modalcontents .ui.segment {
  background-color: rgba(255, 255, 255, 0.5);
}

.demofullscreenbackground .modalcontents .ui.segment .ui.segment {
  background-color: #fff;
}

.ui.basic.segment {
  border-radius: 0.28571429rem;
  padding: 5px;
  z-index: 1;
}

/* fix for bootstrap conflict */
.ui.grid>.row {
  margin: initial;
}

/* hide the back to top button thing */
#back-to-top {
  display: none;
}

/* copy of semantic input styling for the datepicker */
.react-datepicker__input-container>input:not(.ts) {
  outline: none;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.5rem;
  background: #ffffff;
  box-shadow: none;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
}

.ts .react-datepicker-wrapper {
  width: 100%;
}

.ts .react-datepicker__input-container {
  width: 100%;
}

/* copy of semantic input styling for the numeric input */
.react-numeric-input>input {
  outline: none;
  line-height: 1.21428571em;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem !important;
  box-shadow: none;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
}

.react-numeric-input>input:focus {
  border-color: #85b7d9 !important;
}

/* copy of semantic input styling for a textarea */
.semantictextarea {
  outline: none;
  line-height: 1.21428571em;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem !important;
  box-shadow: none;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
}

.semantictextarea:focus {
  border-color: #85b7d9 !important;
}

/* fix the time column alignment in react datepicker */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 5px;
}

/* orangeify datepicker */

.react-datepicker__day--highlighted {
  background-color: rgba(242, 226, 25, 0.5);
  color: black;
  font-weight: 700;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #e46024;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #e46024;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(244, 162, 29, 0.75);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: rgba(244, 162, 29, 0.75);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #e46024;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #e46024;
}

/* show scrollbar next to time selector */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  overflow-y: visible;
}

/* hide menus etc when printing a page */
.onlyprint {
  display: none !important;
}

@media print {
  .menuholder {
    display: none;
  }

  .widget {
    box-shadow: none;
    border: none;
  }

  button {
    display: none !important;
  }

  .dontprint {
    display: none !important;
  }

  .onlyprint {
    display: inherit !important;
  }

  body {
    min-height: initial;
    height: initial;
  }
}

.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

.fluid .react-datepicker-wrapper {
  width: 100%;
}

.fluid .react-datepicker__input-container {
  width: 100%;
}

.tableCellLink {
  color: #428bca;
  cursor: pointer;
}

.break-words {
  white-space: normal;
  word-break: break-word;
}

.fluid .react-numeric-input {
  width: 100%;
}

.react-numeric-input input {
  padding-left: 1em !important;
}

.tabletOutline {
  position: relative;
  margin: auto;
  border: 16px black solid;
  border-top-width: 50px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The circle on the bottom of the device */
.tabletOutline:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The horizontal line on the top of the device */
.tabletOutline:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

.legal-banner {
  position: fixed;
  bottom: 100px;
  left: 10%;
  width: 80%;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  border-color: #e77108;
  background-color: #eee;
  display: flex;
  font-size: 14px;
}

.legal-banner:hover {
  box-shadow: -4px 4px 3px rgba(0, 0, 0, 0.2);
}

.legal-banner-close-button {
  float: right;
  padding: 4px 12px;
  background: #ddd;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16pt;
}

.legal-banner-close-button:hover {
  background: #000;
  color: #ffffff;
}

.tspbody {
  font-family: 'Open Sans', sans-serif;
  overflow-y: auto;
}

.mainPage {
  margin-bottom: 70px;
  /* more than footer height incase it wraps */
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  min-height: 450px;
}

@media (max-width: 600px) {
  .mainPage {
    max-width: 100%;
  }
}

.menuholder {
  box-sizing: content-box;
}

.menuholder:before {
  box-sizing: content-box;
}

.fadeInError {
  animation: fadeIn ease 1s;

  animation-fill-mode: forwards;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Semantic UI uses "inline" on form fields, and tailwind uses inline to display inline, so we override that back to inherit if inside a "ui" form */
.ui.form>.field.inline {
  display: inherit;
}

/* Custom styling for the react date picker */

.react-datepicker-popper {
  z-index: 300 !important;
}

.react-datepicker__time-container {
  width: 80px !important;
}

.react-datepicker__time-box {
  width: 80px !important;
}

.react-datepicker {
  font-family: 'Open Sans' !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-bottom: 10px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: rgb(240, 177, 84);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #f90;
}

.react-datepicker__header--time {
  padding-bottom: 28px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #f90;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: rgb(240, 177, 84);
}

.react-datepicker__day--keyboard-selected {
  background-color: #f90;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  border-width: thin;
  border-color: rgba(171, 185, 204);
  border-radius: 2px;
}