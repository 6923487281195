*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::-webkit-backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
.container {
    width: 100%
}
@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}
.required {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))
}
.btn-primary {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 105 0 / var(--tw-bg-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.btn-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(189 80 3 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(248 248 248 / var(--tw-text-opacity))
}
.btn-secondary {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(165 23 30 / var(--tw-bg-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.btn-secondary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(140 22 25 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity))
}
.btn-cancel {
    border-radius: 0.25rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(255 105 0 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(255 105 0 / var(--tw-text-opacity))
}
.btn-cancel:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(193 55 19 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.btn-disabled {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(146 149 152 / var(--tw-text-opacity))
}
.readonly {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity))
}
.\!disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.\!disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}
.disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}
.ts-date-time .MuiInputBase-input {
    border-width: 0px !important
}
.required-field {
    margin-left: 0.5rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))
}
.error-field {
    --tw-border-opacity: 1 !important;
    border-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity)) !important
}
#root :is(.sr-only) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}
#root :is(.visible) {
    visibility: visible
}
#root :is(.invisible) {
    visibility: hidden
}
#root :is(.static) {
    position: static
}
#root :is(.fixed) {
    position: fixed
}
#root :is(.absolute) {
    position: absolute
}
#root :is(.relative) {
    position: relative
}
#root :is(.sticky) {
    position: -webkit-sticky;
    position: sticky
}
#root :is(.inset-0) {
    inset: 0px
}
#root :is(.inset-x-0) {
    left: 0px;
    right: 0px
}
#root :is(.inset-y-0) {
    top: 0px;
    bottom: 0px
}
#root :is(.bottom-0) {
    bottom: 0px
}
#root :is(.bottom-5) {
    bottom: 1.25rem
}
#root :is(.bottom-\[0\.75em\]) {
    bottom: 0.75em
}
#root :is(.left-0) {
    left: 0px
}
#root :is(.right-0) {
    right: 0px
}
#root :is(.right-2) {
    right: 0.5rem
}
#root :is(.right-5) {
    right: 1.25rem
}
#root :is(.top-0) {
    top: 0px
}
#root :is(.top-20) {
    top: 5rem
}
#root :is(.top-32) {
    top: 8rem
}
#root :is(.top-40) {
    top: 10rem
}
#root :is(.z-10) {
    z-index: 10
}
#root :is(.z-20) {
    z-index: 20
}
#root :is(.z-30) {
    z-index: 30
}
#root :is(.z-40) {
    z-index: 40
}
#root :is(.z-50) {
    z-index: 50
}
#root :is(.z-\[1000\]) {
    z-index: 1000
}
#root :is(.float-none) {
    float: none
}
#root :is(.m-0) {
    margin: 0px
}
#root :is(.m-1) {
    margin: 0.25rem
}
#root :is(.m-2) {
    margin: 0.5rem
}
#root :is(.m-4) {
    margin: 1rem
}
#root :is(.m-auto) {
    margin: auto
}
#root :is(.\!my-1) {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important
}
#root :is(.\!my-2) {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important
}
#root :is(.-my-2) {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
}
#root :is(.mx-1) {
    margin-left: 0.25rem;
    margin-right: 0.25rem
}
#root :is(.mx-2) {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}
#root :is(.mx-4) {
    margin-left: 1rem;
    margin-right: 1rem
}
#root :is(.mx-auto) {
    margin-left: auto;
    margin-right: auto
}
#root :is(.my-10) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
}
#root :is(.my-2) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
}
#root :is(.my-3) {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
}
#root :is(.my-4) {
    margin-top: 1rem;
    margin-bottom: 1rem
}
#root :is(.my-6) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}
#root :is(.\!mb-1) {
    margin-bottom: 0.25rem !important
}
#root :is(.\!mb-10) {
    margin-bottom: 2.5rem !important
}
#root :is(.\!mb-4) {
    margin-bottom: 1rem !important
}
#root :is(.\!ml-2) {
    margin-left: 0.5rem !important
}
#root :is(.\!ml-3) {
    margin-left: 0.75rem !important
}
#root :is(.\!mr-1) {
    margin-right: 0.25rem !important
}
#root :is(.\!mt-10) {
    margin-top: 2.5rem !important
}
#root :is(.-mb-2) {
    margin-bottom: -0.5rem
}
#root :is(.-ml-12) {
    margin-left: -3rem
}
#root :is(.-ml-4) {
    margin-left: -1rem
}
#root :is(.-ml-6) {
    margin-left: -1.5rem
}
#root :is(.-mr-2) {
    margin-right: -0.5rem
}
#root :is(.-mr-4) {
    margin-right: -1rem
}
#root :is(.-mr-8) {
    margin-right: -2rem
}
#root :is(.-mt-1) {
    margin-top: -0.25rem
}
#root :is(.-mt-2) {
    margin-top: -0.5rem
}
#root :is(.-mt-3) {
    margin-top: -0.75rem
}
#root :is(.-mt-4) {
    margin-top: -1rem
}
#root :is(.-mt-5) {
    margin-top: -1.25rem
}
#root :is(.-mt-56) {
    margin-top: -14rem
}
#root :is(.-mt-7) {
    margin-top: -1.75rem
}
#root :is(.-mt-8) {
    margin-top: -2rem
}
#root :is(.-mt-9) {
    margin-top: -2.25rem
}
#root :is(.mb-1) {
    margin-bottom: 0.25rem
}
#root :is(.mb-12) {
    margin-bottom: 3rem
}
#root :is(.mb-2) {
    margin-bottom: 0.5rem
}
#root :is(.mb-4) {
    margin-bottom: 1rem
}
#root :is(.mb-5) {
    margin-bottom: 1.25rem
}
#root :is(.mb-6) {
    margin-bottom: 1.5rem
}
#root :is(.mb-8) {
    margin-bottom: 2rem
}
#root :is(.ml-1) {
    margin-left: 0.25rem
}
#root :is(.ml-10) {
    margin-left: 2.5rem
}
#root :is(.ml-12) {
    margin-left: 3rem
}
#root :is(.ml-2) {
    margin-left: 0.5rem
}
#root :is(.ml-24) {
    margin-left: 6rem
}
#root :is(.ml-3) {
    margin-left: 0.75rem
}
#root :is(.ml-4) {
    margin-left: 1rem
}
#root :is(.ml-6) {
    margin-left: 1.5rem
}
#root :is(.ml-8) {
    margin-left: 2rem
}
#root :is(.mr-1) {
    margin-right: 0.25rem
}
#root :is(.mr-2) {
    margin-right: 0.5rem
}
#root :is(.mr-3) {
    margin-right: 0.75rem
}
#root :is(.mr-4) {
    margin-right: 1rem
}
#root :is(.mr-8) {
    margin-right: 2rem
}
#root :is(.mt-1) {
    margin-top: 0.25rem
}
#root :is(.mt-12) {
    margin-top: 3rem
}
#root :is(.mt-2) {
    margin-top: 0.5rem
}
#root :is(.mt-20) {
    margin-top: 5rem
}
#root :is(.mt-3) {
    margin-top: 0.75rem
}
#root :is(.mt-4) {
    margin-top: 1rem
}
#root :is(.mt-6) {
    margin-top: 1.5rem
}
#root :is(.mt-7) {
    margin-top: 1.75rem
}
#root :is(.mt-8) {
    margin-top: 2rem
}
#root :is(.mt-auto) {
    margin-top: auto
}
#root :is(.block) {
    display: block
}
#root :is(.inline-block) {
    display: inline-block
}
#root :is(.inline) {
    display: inline
}
#root :is(.flex) {
    display: flex
}
#root :is(.inline-flex) {
    display: inline-flex
}
#root :is(.\!table) {
    display: table !important
}
#root :is(.table) {
    display: table
}
#root :is(.grid) {
    display: grid
}
#root :is(.contents) {
    display: contents
}
#root :is(.hidden) {
    display: none
}
#root :is(.\!h-auto) {
    height: auto !important
}
#root :is(.h-10) {
    height: 2.5rem
}
#root :is(.h-12) {
    height: 3rem
}
#root :is(.h-14) {
    height: 3.5rem
}
#root :is(.h-16) {
    height: 4rem
}
#root :is(.h-24) {
    height: 6rem
}
#root :is(.h-32) {
    height: 8rem
}
#root :is(.h-4) {
    height: 1rem
}
#root :is(.h-40) {
    height: 10rem
}
#root :is(.h-5) {
    height: 1.25rem
}
#root :is(.h-6) {
    height: 1.5rem
}
#root :is(.h-8) {
    height: 2rem
}
#root :is(.h-\[30px\]) {
    height: 30px
}
#root :is(.h-auto) {
    height: auto
}
#root :is(.h-full) {
    height: 100%
}
#root :is(.max-h-48) {
    max-height: 12rem
}
#root :is(.max-h-72) {
    max-height: 18rem
}
#root :is(.max-h-\[80\%\]) {
    max-height: 80%
}
#root :is(.max-h-screen) {
    max-height: 100vh
}
#root :is(.min-h-screen) {
    min-height: 100vh
}
#root :is(.w-1\/12) {
    width: 8.333333%
}
#root :is(.w-1\/2) {
    width: 50%
}
#root :is(.w-1\/3) {
    width: 33.333333%
}
#root :is(.w-1\/4) {
    width: 25%
}
#root :is(.w-1\/5) {
    width: 20%
}
#root :is(.w-1\/6) {
    width: 16.666667%
}
#root :is(.w-10\/12) {
    width: 83.333333%
}
#root :is(.w-11\/12) {
    width: 91.666667%
}
#root :is(.w-12) {
    width: 3rem
}
#root :is(.w-2\/12) {
    width: 16.666667%
}
#root :is(.w-2\/3) {
    width: 66.666667%
}
#root :is(.w-20) {
    width: 5rem
}
#root :is(.w-3\/4) {
    width: 75%
}
#root :is(.w-3\/5) {
    width: 60%
}
#root :is(.w-32) {
    width: 8rem
}
#root :is(.w-4) {
    width: 1rem
}
#root :is(.w-4\/12) {
    width: 33.333333%
}
#root :is(.w-4\/5) {
    width: 80%
}
#root :is(.w-40) {
    width: 10rem
}
#root :is(.w-48) {
    width: 12rem
}
#root :is(.w-5) {
    width: 1.25rem
}
#root :is(.w-52) {
    width: 13rem
}
#root :is(.w-6) {
    width: 1.5rem
}
#root :is(.w-8) {
    width: 2rem
}
#root :is(.w-96) {
    width: 24rem
}
#root :is(.w-auto) {
    width: auto
}
#root :is(.w-fit) {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}
#root :is(.w-full) {
    width: 100%
}
#root :is(.min-w-\[16rem\]) {
    min-width: 16rem
}
#root :is(.min-w-full) {
    min-width: 100%
}
#root :is(.max-w-md) {
    max-width: 28rem
}
#root :is(.flex-1) {
    flex: 1 1 0%
}
#root :is(.flex-auto) {
    flex: 1 1 auto
}
#root :is(.flex-initial) {
    flex: 0 1 auto
}
#root :is(.flex-none) {
    flex: none
}
#root :is(.flex-shrink-0) {
    flex-shrink: 0
}
#root :is(.shrink) {
    flex-shrink: 1
}
#root :is(.grow) {
    flex-grow: 1
}
#root :is(.\!basis-10\/12) {
    flex-basis: 83.333333% !important
}
#root :is(.border-collapse) {
    border-collapse: collapse
}
#root :is(.origin-top-left) {
    -webkit-transform-origin: top left;
            transform-origin: top left
}
#root :is(.origin-top-right) {
    -webkit-transform-origin: top right;
            transform-origin: top right
}
#root :is(.translate-x-6) {
    --tw-translate-x: 1.5rem;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
#root :is(.rotate-180) {
    --tw-rotate: 180deg;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
#root :is(.scale-100) {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
#root :is(.scale-95) {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
#root :is(.transform) {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}
@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}
#root :is(.animate-spin) {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
}
#root :is(.cursor-default) {
    cursor: default
}
#root :is(.cursor-move) {
    cursor: move
}
#root :is(.cursor-not-allowed) {
    cursor: not-allowed
}
#root :is(.cursor-pointer) {
    cursor: pointer
}
#root :is(.select-none) {
    -webkit-user-select: none;
            user-select: none
}
#root :is(.resize-none) {
    resize: none
}
#root :is(.resize) {
    resize: both
}
#root :is(.list-none) {
    list-style-type: none
}
#root :is(.grid-cols-2) {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
#root :is(.grid-cols-4) {
    grid-template-columns: repeat(4, minmax(0, 1fr))
}
#root :is(.flex-row) {
    flex-direction: row
}
#root :is(.flex-row-reverse) {
    flex-direction: row-reverse
}
#root :is(.flex-col) {
    flex-direction: column
}
#root :is(.flex-wrap) {
    flex-wrap: wrap
}
#root :is(.items-center) {
    align-items: center
}
#root :is(.justify-start) {
    justify-content: flex-start
}
#root :is(.justify-end) {
    justify-content: flex-end
}
#root :is(.justify-center) {
    justify-content: center
}
#root :is(.justify-between) {
    justify-content: space-between
}
#root :is(.justify-items-center) {
    justify-items: center
}
#root :is(.gap-1) {
    gap: 0.25rem
}
#root :is(.gap-4) {
    gap: 1rem
}
#root :is(.space-y-1 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}
#root :is(.divide-y > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}
#root :is(.divide-gray-300 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-divide-opacity))
}
#root :is(.divide-gray-500 > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-divide-opacity))
}
#root :is(.self-end) {
    align-self: flex-end
}
#root :is(.self-center) {
    align-self: center
}
#root :is(.overflow-auto) {
    overflow: auto
}
#root :is(.overflow-hidden) {
    overflow: hidden
}
#root :is(.overflow-x-auto) {
    overflow-x: auto
}
#root :is(.overflow-y-auto) {
    overflow-y: auto
}
#root :is(.overflow-x-hidden) {
    overflow-x: hidden
}
#root :is(.text-clip) {
    text-overflow: clip
}
#root :is(.whitespace-nowrap) {
    white-space: nowrap
}
#root :is(.break-words) {
    overflow-wrap: break-word
}
#root :is(.break-all) {
    word-break: break-all
}
#root :is(.rounded) {
    border-radius: 0.25rem
}
#root :is(.rounded-full) {
    border-radius: 9999px
}
#root :is(.rounded-md) {
    border-radius: 0.375rem
}
#root :is(.rounded-b-lg) {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
}
#root :is(.rounded-l) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
}
#root :is(.rounded-r) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
}
#root :is(.rounded-t-lg) {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
}
#root :is(.border) {
    border-width: 1px
}
#root :is(.border-0) {
    border-width: 0px
}
#root :is(.border-2) {
    border-width: 2px
}
#root :is(.border-b) {
    border-bottom-width: 1px
}
#root :is(.border-b-0) {
    border-bottom-width: 0px
}
#root :is(.border-b-2) {
    border-bottom-width: 2px
}
#root :is(.border-l) {
    border-left-width: 1px
}
#root :is(.border-l-0) {
    border-left-width: 0px
}
#root :is(.border-r) {
    border-right-width: 1px
}
#root :is(.border-t-0) {
    border-top-width: 0px
}
#root :is(.border-solid) {
    border-style: solid
}
#root :is(.border-black) {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity))
}
#root :is(.border-cyan-500) {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity))
}
#root :is(.border-dark-gray-darker) {
    --tw-border-opacity: 1;
    border-color: rgb(85 85 85 / var(--tw-border-opacity))
}
#root :is(.border-error) {
    --tw-border-opacity: 1;
    border-color: rgb(211 52 52 / var(--tw-border-opacity))
}
#root :is(.border-gray-100) {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity))
}
#root :is(.border-gray-200) {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity))
}
#root :is(.border-gray-300) {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))
}
#root :is(.border-gray-400) {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity))
}
#root :is(.border-gray-500) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity))
}
#root :is(.border-gray-600) {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity))
}
#root :is(.border-gray-700) {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity))
}
#root :is(.border-green-500) {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity))
}
#root :is(.border-light-gray) {
    --tw-border-opacity: 1;
    border-color: rgb(248 248 248 / var(--tw-border-opacity))
}
#root :is(.border-orange-400) {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity))
}
#root :is(.border-orange-500) {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity))
}
#root :is(.border-orange-700) {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity))
}
#root :is(.border-standard-red) {
    --tw-border-opacity: 1;
    border-color: rgb(219 31 38 / var(--tw-border-opacity))
}
#root :is(.border-yellow-600) {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity))
}
#root :is(.bg-basket) {
    --tw-bg-opacity: 1;
    background-color: rgb(162 222 150 / var(--tw-bg-opacity))
}
#root :is(.bg-black) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}
#root :is(.bg-cashier) {
    --tw-bg-opacity: 1;
    background-color: rgb(233 113 113 / var(--tw-bg-opacity))
}
#root :is(.bg-cyan-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity))
}
#root :is(.bg-dark-gray-darker) {
    --tw-bg-opacity: 1;
    background-color: rgb(85 85 85 / var(--tw-bg-opacity))
}
#root :is(.bg-errorBackground) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 194 194 / var(--tw-bg-opacity))
}
#root :is(.bg-gray-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
#root :is(.bg-gray-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}
#root :is(.bg-gray-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}
#root :is(.bg-gray-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}
#root :is(.bg-gray-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}
#root :is(.bg-gray-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}
#root :is(.bg-gray-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}
#root :is(.bg-green-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity))
}
#root :is(.bg-green-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity))
}
#root :is(.bg-item) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 212 109 / var(--tw-bg-opacity))
}
#root :is(.bg-light-gray) {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity))
}
#root :is(.bg-light-red-lighter) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 243 244 / var(--tw-bg-opacity))
}
#root :is(.bg-lightgreen) {
    --tw-bg-opacity: 1;
    background-color: rgb(149 190 191 / var(--tw-bg-opacity))
}
#root :is(.bg-medium-gray) {
    --tw-bg-opacity: 1;
    background-color: rgb(231 231 231 / var(--tw-bg-opacity))
}
#root :is(.bg-orange-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity))
}
#root :is(.bg-orange-50) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity))
}
#root :is(.bg-other) {
    --tw-bg-opacity: 1;
    background-color: rgb(110 192 211 / var(--tw-bg-opacity))
}
#root :is(.bg-primary) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 105 0 / var(--tw-bg-opacity))
}
#root :is(.bg-primary-lighter) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 199 160 / var(--tw-bg-opacity))
}
#root :is(.bg-secondary) {
    --tw-bg-opacity: 1;
    background-color: rgb(165 23 30 / var(--tw-bg-opacity))
}
#root :is(.bg-shopper) {
    --tw-bg-opacity: 1;
    background-color: rgb(79 138 139 / var(--tw-bg-opacity))
}
#root :is(.bg-table-gray) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 240 240 / var(--tw-bg-opacity))
}
#root :is(.bg-trader) {
    --tw-bg-opacity: 1;
    background-color: rgb(231 156 194 / var(--tw-bg-opacity))
}
#root :is(.bg-transparent) {
    background-color: transparent
}
#root :is(.bg-white) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
#root :is(.bg-opacity-100) {
    --tw-bg-opacity: 1
}
#root :is(.bg-opacity-25) {
    --tw-bg-opacity: 0.25
}
#root :is(.fill-gray-400) {
    fill: #9ca3af
}
#root :is(.fill-gray-500) {
    fill: #6b7280
}
#root :is(.fill-white) {
    fill: #fff
}
#root :is(.p-0) {
    padding: 0px
}
#root :is(.p-1) {
    padding: 0.25rem
}
#root :is(.p-2) {
    padding: 0.5rem
}
#root :is(.p-3) {
    padding: 0.75rem
}
#root :is(.p-4) {
    padding: 1rem
}
#root :is(.px-10) {
    padding-left: 2.5rem;
    padding-right: 2.5rem
}
#root :is(.px-12) {
    padding-left: 3rem;
    padding-right: 3rem
}
#root :is(.px-2) {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
#root :is(.px-3) {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
#root :is(.px-4) {
    padding-left: 1rem;
    padding-right: 1rem
}
#root :is(.px-6) {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}
#root :is(.px-8) {
    padding-left: 2rem;
    padding-right: 2rem
}
#root :is(.py-0) {
    padding-top: 0px;
    padding-bottom: 0px
}
#root :is(.py-1) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}
#root :is(.py-1\.5) {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
}
#root :is(.py-12) {
    padding-top: 3rem;
    padding-bottom: 3rem
}
#root :is(.py-16) {
    padding-top: 4rem;
    padding-bottom: 4rem
}
#root :is(.py-2) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
#root :is(.py-3) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}
#root :is(.py-36) {
    padding-top: 9rem;
    padding-bottom: 9rem
}
#root :is(.py-4) {
    padding-top: 1rem;
    padding-bottom: 1rem
}
#root :is(.py-6) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}
#root :is(.py-8) {
    padding-top: 2rem;
    padding-bottom: 2rem
}
#root :is(.pb-10) {
    padding-bottom: 2.5rem
}
#root :is(.pb-2) {
    padding-bottom: 0.5rem
}
#root :is(.pb-3) {
    padding-bottom: 0.75rem
}
#root :is(.pb-4) {
    padding-bottom: 1rem
}
#root :is(.pb-48) {
    padding-bottom: 12rem
}
#root :is(.pb-6) {
    padding-bottom: 1.5rem
}
#root :is(.pl-2) {
    padding-left: 0.5rem
}
#root :is(.pl-8) {
    padding-left: 2rem
}
#root :is(.pr-2) {
    padding-right: 0.5rem
}
#root :is(.pr-4) {
    padding-right: 1rem
}
#root :is(.pt-1) {
    padding-top: 0.25rem
}
#root :is(.pt-2) {
    padding-top: 0.5rem
}
#root :is(.pt-4) {
    padding-top: 1rem
}
#root :is(.\!text-left) {
    text-align: left !important
}
#root :is(.text-left) {
    text-align: left
}
#root :is(.text-center) {
    text-align: center
}
#root :is(.text-right) {
    text-align: right
}
#root :is(.align-middle) {
    vertical-align: middle
}
#root :is(.text-base) {
    font-size: 1rem;
    line-height: 1.5rem
}
#root :is(.text-lg) {
    font-size: 1.125rem;
    line-height: 1.75rem
}
#root :is(.text-sm) {
    font-size: 0.875rem;
    line-height: 1.25rem
}
#root :is(.text-xs) {
    font-size: 0.75rem;
    line-height: 1rem
}
#root :is(.font-bold) {
    font-weight: 700
}
#root :is(.font-medium) {
    font-weight: 500
}
#root :is(.font-normal) {
    font-weight: 400
}
#root :is(.font-semibold) {
    font-weight: 600
}
#root :is(.font-thin) {
    font-weight: 100
}
#root :is(.italic) {
    font-style: italic
}
#root :is(.text-black) {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
#root :is(.text-blue-300) {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity))
}
#root :is(.text-blue-500) {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity))
}
#root :is(.text-cyan-500) {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity))
}
#root :is(.text-dark-gray) {
    --tw-text-opacity: 1;
    color: rgb(231 231 231 / var(--tw-text-opacity))
}
#root :is(.text-error) {
    --tw-text-opacity: 1;
    color: rgb(211 52 52 / var(--tw-text-opacity))
}
#root :is(.text-gray-400) {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}
#root :is(.text-gray-500) {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
#root :is(.text-gray-600) {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
}
#root :is(.text-gray-700) {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}
#root :is(.text-gray-800) {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity))
}
#root :is(.text-green-500) {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity))
}
#root :is(.text-inherit) {
    color: inherit
}
#root :is(.text-medium-gray) {
    --tw-text-opacity: 1;
    color: rgb(231 231 231 / var(--tw-text-opacity))
}
#root :is(.text-orange-400) {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity))
}
#root :is(.text-orange-500) {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity))
}
#root :is(.text-primary) {
    --tw-text-opacity: 1;
    color: rgb(255 105 0 / var(--tw-text-opacity))
}
#root :is(.text-purple-700) {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity))
}
#root :is(.text-red-500) {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity))
}
#root :is(.text-red-600) {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))
}
#root :is(.text-standard-gray) {
    --tw-text-opacity: 1;
    color: rgb(146 149 152 / var(--tw-text-opacity))
}
#root :is(.text-standard-red) {
    --tw-text-opacity: 1;
    color: rgb(219 31 38 / var(--tw-text-opacity))
}
#root :is(.text-white) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
#root :is(.text-yellow-600) {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity))
}
#root :is(.underline) {
    text-decoration-line: underline
}
#root :is(.placeholder-gray-500)::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(107 114 128 / var(--tw-placeholder-opacity))
}
#root :is(.placeholder-gray-500)::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(107 114 128 / var(--tw-placeholder-opacity))
}
#root :is(.opacity-0) {
    opacity: 0
}
#root :is(.opacity-100) {
    opacity: 1
}
#root :is(.opacity-50) {
    opacity: 0.5
}
#root :is(.shadow) {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
#root :is(.shadow-lg) {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
#root :is(.shadow-md) {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
#root :is(.shadow-xl) {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
#root :is(.outline-none) {
    outline: 2px solid transparent;
    outline-offset: 2px
}
#root :is(.outline) {
    outline-style: solid
}
#root :is(.blur) {
    --tw-blur: blur(8px);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
#root :is(.\!filter) {
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}
#root :is(.filter) {
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
#root :is(.backdrop-filter) {
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}
#root :is(.transition) {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
#root :is(.duration-100) {
    transition-duration: 100ms
}
#root :is(.duration-75) {
    transition-duration: 75ms
}
#root :is(.ease-in) {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}
#root :is(.ease-out) {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}
#root :is(.first\:rounded-l:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
}
#root :is(.last\:rounded-r:last-child) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
}
#root :is(.odd\:\!bg-highlight:nth-child(odd)) {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 199 160 / var(--tw-bg-opacity)) !important
}
#root :is(.odd\:bg-highlight:nth-child(odd)) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 199 160 / var(--tw-bg-opacity))
}
#root :is(.odd\:bg-white:nth-child(odd)) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
#root :is(.even\:\!bg-highlight:nth-child(even)) {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 199 160 / var(--tw-bg-opacity)) !important
}
#root :is(.even\:bg-gray-100:nth-child(even)) {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
#root :is(.even\:bg-gray-50:nth-child(even)) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}
#root :is(.even\:bg-highlight:nth-child(even)) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 199 160 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-baskethover:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(105 137 98 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-cashierhover:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(160 77 77 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-dark-gray:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(231 231 231 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-gray-200:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-gray-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-gray-600:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-gray-800:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-itemhover:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(170 141 77 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-otherhover:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(74 128 137 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-primary:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 105 0 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-primary-darker:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(189 80 3 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-secondary:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(165 23 30 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-shopperhover:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(50 91 91 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-traderhover:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(173 112 143 / var(--tw-bg-opacity))
}
#root :is(.hover\:bg-opacity-75:hover) {
    --tw-bg-opacity: 0.75
}
#root :is(.hover\:text-blue-700:hover) {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity))
}
#root :is(.hover\:text-dark-gray-darker:hover) {
    --tw-text-opacity: 1;
    color: rgb(85 85 85 / var(--tw-text-opacity))
}
#root :is(.hover\:text-gray-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
#root :is(.hover\:text-gray-600:hover) {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
}
#root :is(.hover\:text-inherit:hover) {
    color: inherit
}
#root :is(.hover\:text-primary-darker:hover) {
    --tw-text-opacity: 1;
    color: rgb(189 80 3 / var(--tw-text-opacity))
}
#root :is(.hover\:text-white:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
#root :is(.focus\:bg-opacity-100:focus) {
    --tw-bg-opacity: 1
}
#root :is(.focus\:outline-none:focus) {
    outline: 2px solid transparent;
    outline-offset: 2px
}
#root :is(.focus\:ring-2:focus) {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
#root :is(.focus\:ring-inset:focus) {
    --tw-ring-inset: inset
}
#root :is(.focus\:ring-white:focus) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))
}
@media (min-width: 768px) {
    #root :is(.md\:float-right) {
        float: right
    }
    #root :is(.md\:inline) {
        display: inline
    }
    #root :is(.md\:w-1\/5) {
        width: 20%
    }
    #root :is(.md\:text-left) {
        text-align: left
    }
    #root :is(.md\:text-right) {
        text-align: right
    }
}
@media (min-width: 1024px) {
    #root :is(.lg\:table-cell) {
        display: table-cell
    }
    #root :is(.lg\:grid) {
        display: grid
    }
    #root :is(.lg\:hidden) {
        display: none
    }
    #root :is(.lg\:px-8) {
        padding-left: 2rem;
        padding-right: 2rem
    }
}
@media (min-width: 1280px) {
    #root :is(.xl\:static) {
        position: static
    }
    #root :is(.xl\:inset-auto) {
        inset: auto
    }
    #root :is(.xl\:ml-6) {
        margin-left: 1.5rem
    }
    #root :is(.xl\:flex) {
        display: flex
    }
    #root :is(.xl\:table-cell) {
        display: table-cell
    }
    #root :is(.xl\:table-row-group) {
        display: table-row-group
    }
    #root :is(.xl\:table-row) {
        display: table-row
    }
    #root :is(.xl\:hidden) {
        display: none
    }
    #root :is(.xl\:w-1\/2) {
        width: 50%
    }
    #root :is(.xl\:w-2\/6) {
        width: 33.333333%
    }
    #root :is(.xl\:items-stretch) {
        align-items: stretch
    }
    #root :is(.xl\:justify-start) {
        justify-content: flex-start
    }
    #root :is(.xl\:px-3) {
        padding-left: 0.75rem;
        padding-right: 0.75rem
    }
    #root :is(.xl\:px-6) {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
    #root :is(.xl\:py-12) {
        padding-top: 3rem;
        padding-bottom: 3rem
    }
    #root :is(.xl\:py-2) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem
    }
    #root :is(.xl\:pr-0) {
        padding-right: 0px
    }
}